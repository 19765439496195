import React from "react"
import Container from "../components/container"
import SEO from "../components/seo"
import FAQ from "../components/faqComponent"
import "../theme/faq-style.css"

const FAQPage = () => (
  <Container defKey="1">
    <SEO title="Page two" />
    <div className="faq-container">
      <h1 className="faq-title">Frequently Asked Questions (FAQ)</h1>
      <FAQ faqs={data} />
    </div>
  </Container>
)

const data = [
  {
    question:
      "Hello there Hello there Hello there Hello there Hello there Hello there Hello there Hello there Hello there Hello there Hello there Hello there Hello there Hello there Hello there?",
    answer:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia consectetur a, eos blanditiis ex illo natus atque quasi consequuntur ea labore assumenda facilis molestias est neque commodi ut maxime nisi!Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia consectetur a, eos blanditiis ex illo natus atque quasi consequuntur ea labore assumenda facilis molestias est neque commodi ut maxime nisi!Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia consectetur a, eos blanditiis ex illo natus atque quasi consequuntur ea labore assumenda facilis molestias est neque commodi ut maxime nisi!Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia consectetur a, eos blanditiis ex illo natus atque quasi consequuntur ea labore assumenda facilis molestias est neque commodi ut maxime nisi!",
  },
  {
    question: "You what?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, modi nobis facilis, praesentium temporibus incidunt ipsam vitae in, accusantium animi sunt. In est non voluptatum nostrum atque totam facilis iste!",
  },
  {
    question: "A third question, I have?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui alias dignissimos dolorum pariatur, consequuntur, ex tempore eos ipsa ratione quos ullam eaque rem magnam nesciunt facilis odio quia neque hic?",
  },
  {
    question: "A fourth question, I have?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui alias dignissimos dolorum pariatur, consequuntur, ex tempore eos ipsa ratione quos ullam eaque rem magnam nesciunt facilis odio quia neque hic?",
  },
  {
    question: "A fifth question?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui alias dignissimos dolorum pariatur, consequuntur, ex tempore eos ipsa ratione quos ullam eaque rem magnam nesciunt facilis odio quia neque hic?",
  },
  {
    question: "A sixth question?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui alias dignissimos dolorum pariatur, consequuntur, ex tempore eos ipsa ratione quos ullam eaque rem magnam nesciunt facilis odio quia neque hic?",
  },
  {
    question: "A 7 question?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui alias dignissimos dolorum pariatur, consequuntur, ex tempore eos ipsa ratione quos ullam eaque rem magnam nesciunt facilis odio quia neque hic?",
  },
  {
    question: "Question 8?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui alias dignissimos dolorum pariatur, consequuntur, ex tempore eos ipsa ratione quos ullam eaque rem magnam nesciunt facilis odio quia neque hic?",
  },
]

export default FAQPage
